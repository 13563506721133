import logo from './logo.svg';
import mqtt from 'mqtt'
import { useState } from "react";

const client = mqtt.connect("wss://test.mosquitto.org:8081", {protocolVersion: 5})

function App() {

  client.on("connect", () => {
    console.log("connected");
    client.subscribe('G1/City-1/Home-1/ESP/did-1', {}, (error) => {
      if (error) {
        console.error('subscription failed', error)
      }
    })
  })

  client.on("error", (err) => {
    console.error(err);
  })

  client.on('message', (topic, payload) => {
    payload = payload.toString().trim()
    payload = payload.substring(payload.indexOf('{'))
    console.log('Received Message:', topic, payload)
    if(topic === 'G1/City-1/Home-1/ESP/did-1'){
      let x = JSON.parse(payload)
      if(x.did == 2){
        console.log('here: x.switch = ', x.switch.toString())
        setBColor(x.switch? "red": "blue")
      }else if(x.did == 4){
        console.log('here: x.value = ', x.value.toString())
        setRValue(x.value)
      }
    }
  })

  const setPin = () => {
    console.log("publishing")
    setLColor(LColor == "white"? "red": "white");
    client.publish('G1/City-1/Home-1/ESP/did-1', JSON.stringify({did: '1', switch: LColor == "white"? true: false}), { qos: 0, retain: false }, (error) => {
      if (error) {
        console.error(error)
        setLColor(LColor == "white"? "red": "white");
      }
    })
  }

  const changed = (v) => {
    console.log("range changed: ", v.target.value)
    client.publish('G1/City-1/Home-1/ESP/did-1', JSON.stringify({did: '3', value: v.target.value}), { qos: 0, retain: false }, (error) => {
      if (error) {
        console.error(error)
      }
    })
  }

  const [bColor, setBColor] = useState('blue')
  const [LColor, setLColor] = useState('white')
  const [rValue, setRValue] = useState(50)

  return (
    <div>
      <button style={{
        'backgroundColor': LColor,
        'border': 'solid 2px blue',
        'margin': '10px',
        'padding': '20px',
        'position': 'relative',
        'left': '50vw',
        'transform': 'translate(-50%,0)',
        'cursor': 'pointer',
        'transition': 'all 0.5s'
      }} onClick={setPin}>setPin</button>
      <div style={{
        'backgroundColor': bColor,
        'border': 'solid 2px blue',
        'margin': '10px',
        'padding': '20px',
        'position': 'relative',
        'left': '50vw',
        'transform': 'translate(-50%,0)',
        'width': '50px',
        'height': '50px',
        'transition': 'all 0.5s'
      }}></div>
      <input type="range" min="1" max="100" style={{
        'display': 'block',
        'backgroundColor': 'white',
        'border': 'solid 2px blue',
        'margin': '10px',
        'padding': '20px',
        'position': 'relative',
        'left': '50vw',
        'transform': 'translate(-50%, 0)'
      }} onChange={changed}/>
      <input type="range" min="1" max="100" style={{
        'display': 'block',
        'backgroundColor': 'white',
        'border': 'solid 2px blue',
        'margin': '10px',
        'padding': '20px',
        'position': 'relative',
        'left': '50vw',
        'transform': 'translate(-50%, 0)'
      }} disabled={true} value={rValue}/>
    </div> 
  );
}

export default App;
